import React from "react"
import Layout from "../components/layout"
import { Column, SEOTitle, Container, Wrapper } from "../theme/index"
import { Mailchimp } from "../components"

const OnlineChecklistPage = () => {
  return (
    <Layout title="Online Checklist">
      <Wrapper>
        <Container>
          <Column width="50%" margin="0 auto">
            <SEOTitle style={{ margin: "40px auto" }}>
              Sign up to the CheshTech Newsletter and get a FREE Online
              Checklist
            </SEOTitle>
            <Mailchimp />
          </Column>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default OnlineChecklistPage
